@import './_settings';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

h1, h2 {
    margin: 0;
    padding: 0;
}

.cursor__pointer {
    cursor: pointer;
}

.no_underline {
    text-decoration: none !important;
}

.ch2-icon {
    display: none !important;
}

a.square, .like__a {
    transition: 500ms $transition__standart;
}

a.square:focus, .like__a:focus {
    outline: none;
}

a.square:hover, .like__a:hover, a.square:focus, .like__a:focus {
    opacity: 0.5;
    cursor: pointer;
    transition: 250ms $transition__standart;
}

.resize-animation-stopper * {
    animation: none !important;
    transition: none !important;
}

.quote {
    position: relative;
    z-index: 1;
}

.quote::before {
    content: '„';
    position: absolute;
    font-family: oswald,sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12vw;
    top: -1.1vw;
    left: 2vw;
    color: rgba(0,0,0,0.15);
    z-index: -1;
}

.max-width-75 {
    width: 75%;
}

.inline-block {
    display: inline-block;
}

.max-width-70 {
    width: 70%;
}

.max-width-80 {
    width: 80%;
}

.square__picture {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

.square__picture__background {
    background-position: center;
    background-size: cover;
}

.header__regular {
    font-family: oswald,sans-serif;
    font-weight: 400;
    font-style: normal;
}

.font__regular, .font__heavy, .font__book {
    font-family: futura-pt,sans-serif;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.2rem;
}

.font__regular {
    font-weight: 500;
}

.font__heavy {
    font-weight: 700;
}

.font__book {
    font-weight: 400;
}

.bold {
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
}

.background-color__first-color {
    background-color: $first-color;
}

.background-color__second-color {
    background-color: $second-color;
}

.background-color__third-color {
    background-color: $third-color;
}

.background-color__fourth-color {
    background-color: $fourth-color;
}

.color__first-color {
    color: $first-color;
}

.color__second-color {
    color: $second-color;
}

.color__third-color {
    color: $third-color;
}

.color__fourth-color {
    color: $fourth-color;
}

.show {
    display: block;
}

.isLoading {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile__no-align {
    align-items: flex-start;
    margin: 15% 0;
}

.flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-mid-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

// INDIVIDUAL SQUARE STYLES

.content__inner__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.heading__big, .heading__big__upper  {
    font-size: 8vw;
    line-height: 6.3vw;
    text-transform: uppercase;
    font-family: oswald, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.heading__big__fix {
    line-height: 8.3vw;
}

.heading__big__upper  {
    font-size: 4vw;
    line-height: 4.15vw;
}

.heading__normal {
    font-size: 5.33333333333vw;
    line-height: 4vw;
    text-transform: uppercase;
    font-family: oswald, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.heading__normal__fix {
    line-height: 6vw;
}

.heading__small {
    font-size: 2.66666666666vw;
    line-height: 2.6666666666vw;
    text-transform: uppercase;
    font-family: oswald, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.button__small, .button__yellow {
    display: inline-block;
    font-size: 2.5vw;
    text-transform: uppercase;
    font-family: futura-pt,sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 1.5vw 3.5vw;
    text-align: center;
    border-radius: 35px;
    border: 1px solid $third-color;
    margin-top: 3.5vw;
    text-decoration: none;
    position: relative;
    background-color: transparent;
    overflow: hidden;
}

.button__small:after, .button__yellow:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 35px;
    transition: 500ms $transition__standart;
}

.button__small:after {
    background-color: rgba(255,255,255,0.15);
}

.button__yellow:after {
    background-color: rgba(249,188,51,0.15);
}

.button__small:focus, .button__yellow:focus, .button__small:focus:after, .button__yellow:focus:after {
    outline: none
}

.button__small:hover:after, .button__yellow:hover:after, .button__small:focus:after, .button__yellow:focus:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 35px;
    transition: 500ms $transition__standart;
}



.button__yellow {
    border: 1px solid $second-color;
}

p, .p__class {        
    font-family: futura-pt,sans-serif;
    font-style: normal;
    font-size: 1.8rem;
}


 /* SECTIONS MOBILE /////////////////////////////////// */

 .four-one, .one-four {
    display: grid;
    grid-template-columns: repeat(1, minmax(100%, 1fr));
    grid-auto-rows: 1fr;
}

.two-one-two, .blog-grid, .one-one-news {
    display: grid;
    grid-template-columns: repeat(1, minmax(100%, 1fr));
}

.one-one-one-one, .trainings {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    grid-auto-rows: 1fr;
}

.one-one {
    display: grid;
    grid-template-columns: repeat(1, minmax(100%, 1fr));
}

  /* BLOCKS MOBILE /////////////////////////////////// */

.block-2x2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    grid-auto-rows: 1fr;
}

.block-1x1 {
    display: grid;
    grid-template-columns: repeat(1, minmax(100%, 1fr));
    grid-auto-rows: 1fr;
}

.block-1x1-small {
    display: grid;
    grid-template-columns: repeat(1, minmax(50%, 1fr));
    grid-auto-rows: 1fr;
}

.block-1x2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    grid-auto-rows: 1fr;
}

.block-1xn {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
}

  /* SQUARES DO NOT TOUCH! /////////////////////////////////// */

.square {
    position: relative;
    box-sizing: border-box;
}

.square::before {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.square__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 400px) {
    .font__regular, .font__heavy, .font__book {
        font-size: 2.5vw;
        line-height: 1.15;
    }
  }

  @media (min-width:700px) {
    .one-one-news {
        grid-template-columns: repeat(2, minmax(50%, 1fr));
    }
  }


  /* SPECIAL CLASSES /////////////////////////////////// */

@media (max-width: 959px) {



    .hide__mobile {
        display: none;
    }

    .square__grow__mobile::before {
        padding-bottom: 0;
    } 

    .square__content__grow__mobile {
        position: static;
    }

    .order-to-start__mobile {
        order: -1;
    }

    .order-to-end__mobile {
        order: 999;
    }

}

@media (min-width: 960px) {

    .position-relative {
        position: relative;
    }

    .position-absolute {
        position: absolute;
    }

    .team-more-info {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        transition: 500ms $transition__standart;
    }

    .team-more-info__is-open {
        opacity: 1;
        pointer-events: auto;
        transition: 500ms $transition__standart;
    }

    .team-more-info .max-width-80 p:first-of-type {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: bold;
    }

    .team__info-close {
        text-decoration: underline;
        font-weight: bold;
    }

    .mobile__no-align {
        align-items: center;
        margin: 0;
    }

    .font__regular, .font__heavy, .font__book {
        font-size: 1.2vw;
    }

    // INDIVIDUAL SQUARE STYLES

    .button__small, .button__yellow {
        display: inline-block;
        font-size: 1.2vw;
        text-transform: uppercase;
        font-family: futura-pt,sans-serif;
        font-style: normal;
        font-weight: 700;
        padding: .5vw 1.5vw;
        text-align: center;
        border-radius: 35px;
        border: 1px solid $third-color;
        margin-top: .5vw;
    }

    .button__yellow {
        border: 1px solid $second-color;
    }


    .content__inner__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .heading__big {
        font-size: 4vw;
        line-height: 4.1vw;
        text-transform: uppercase;
        font-family: oswald, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .heading__big__upper {
        font-size: 2vw;
        line-height: 2.05vw;
    }

    .heading__normal {
        font-size: 2.666666666666666vw;
        line-height: 1.3vw;
        text-transform: uppercase;
        font-family: oswald, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .heading__normal__fix {
        line-height: 3vw;
    }
    
    .heading__small {
        font-size: 1.33333333vw;
        line-height: 2.6666666666vw;
        text-transform: uppercase;
        font-family: oswald, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    p {        
        font-family: futura-pt,sans-serif;
        font-style: normal;
        font-size: 1.6rem;
    }

     /* SECTIONS LANDSCAPE /////////////////////////////////// */

    .four-one, .one-four {
        grid-template-columns: repeat(2, minmax(50%, 1fr));
    }

    .two-one-two, .blog-grid {
        grid-template-columns: minmax(25%, 1fr) minmax(50%, 1fr) minmax(25%, 1fr);
    }

    .one-one-one-one, .trainings {
        grid-template-columns: repeat(4, minmax(25%, 1fr));
    }

    .one-one {
        grid-template-columns: repeat(2, minmax(50%, 1fr));
    }

    /* BLOCKS LANDSCAPE /////////////////////////////////// */

    .block-2x2 {
        grid-template-columns: repeat(2, minmax(50%, 1fr));
    }
    
    .block-1x1, .block-1x2, .block-1xn  {
        grid-template-columns: repeat(1, minmax(100%, 1fr));
    }

    .square__grow::before {
        padding-bottom: 0;
    } 

    .square__content__grow {
        position: static;
    }
}

@media (min-width: 1366px) {

    .team-more-info .max-width-80 p:first-of-type {
        font-size: 2rem;
        line-height: 2.5rem;
    }

  }

@media (min-width: 1600px) {
    p {        
        font-family: futura-pt,sans-serif;
        font-style: normal;
        font-size: 2rem;
        line-height: 2.5rem;
    }

        .team-more-info .max-width-80 p:first-of-type {
        font-size: 2.5rem;
        line-height: 3rem;
    }
}

@media (min-width: 1800px) {
    p {        
        font-family: futura-pt,sans-serif;
        font-style: normal;
        font-size: 2rem;
    }
}


@media (orientation: landscape) and (min-aspect-ratio: 20/9) {
    body {
        transform: scale(.7) translateY(-20%);
        box-shadow: $second-color 20px 20px 0;
    }
}

@media (hover: none) {
    .button__small:after, .button__yellow:after {
        transition: all;
    }
    
    .button__small:hover:after, .button__yellow:hover:after {
        transition: all;
    }
  }

