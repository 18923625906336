@import '../../base.styles/_settings';

.nomatch {
    width: 100%;
    background-color: $second-color;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 75%;
        max-width: 500px;
    }
}