@import '../../base.styles/_settings';

.formError {
    color: red !important;
}

.formError::placeholder {
    color: red !important;
}

@media (max-width: 374px) {
    .g-recaptcha {
        transform:scale(0.77);
        -webkit-transform:scal(0.77);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        } 
}
.icon__contact {
    width: 20%;
    height: auto;
    margin-right: 0;
    margin-bottom: 2vw;;
}

.contact__padding {
    margin: 15vw 0;
}

.green__bg {
    background-color: greenyellow !important;
}

.red__bg {
    display: red !important;
}

.p__priv {
    font-size: 1.5rem;
    margin: 2vw 0 5vw 0;
    color: $fourth-color;
}

.p__error {
    font-size: 1.5rem;
    margin: 2vw 0 5vw 0;
    color: red;
}

.flex__fix {
    flex: 1;
}

.font__contact {
    font-size: 3vw !important;
}

#contact {

    form {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    
    form * {
        width: 100%;
        font-family: futura-pt,sans-serif;
        font-style: normal;
        font-weight: 700;
    }

    form *:focus {
        border-radius: 0;
        border: solid #000 1vw;
    }

    form input:focus  {
        padding: 0 2vw;
    }

    form textarea:focus  {
        padding: 3vw 2vw;
    }
    
    form input {
        border: none;
        border-radius: 0;
        height: 10vw;
        margin-bottom: 7.5vw;
        font-size: 3vw;
        padding: 1vw 3vw;
        color: $fourth-color;
    }
    
    textarea {
        display: block;
        border: none;
        border-radius: 0;
        height: 30vw;
        margin-bottom: 7.5vw;
        font-size: 3vw;
        padding: 4vw 3vw;
        color: $fourth-color;
    }
    
    .form__button {
        display: block;
        height: 10vw;
        background-color: $fourth-color;
        border: none;
        border-radius: 0;
        color: $third-color;
        font-size: 6vw;
        letter-spacing: 1px;
        margin-bottom: 0;
    }

}



@media (min-width: 960px) {

    .icon__contact {
        width: 20%;
        height: auto;
        margin-right: 0;
        margin-bottom: 1vw;;
    }


    
    .flex__fix {
        flex: 1;
    }
    
    .font__contact {
        font-size: 1.5vw !important;
    }


    #contact {

        form {
            justify-content: space-between;
        }
        
        form * {
            width: auto;
            font-family: futura-pt,sans-serif;
            font-style: normal;
            font-weight: 700;
        }
    
        form *:focus {
            border-radius: 0;
            border: solid #000 .2vw;
        }
    
        form input:focus  {
            padding: .3vw .8vw;
        }
    
        form textarea:focus  {
            padding: 1.3vw 0.8vw;
        }
        
        form input {
            width: 43%;
            border: none;
            border-radius: 0;
            height: 3vw;
            margin-bottom: 2vw;
            font-size: 1vw;
            padding: .5vw 1vw;
            color: $fourth-color;
        }
        
        textarea {
            display: block;
            border: none;
            width: 100%;
            border-radius: 0;
            height: 5vw;
            margin-bottom: 2vw;
            font-size: 1vw;
            padding: 1.5vw 1vw;
            color: $fourth-color;
        }

        .p__priv {
            font-size: 1rem;
            margin: 1vw 0;
            color: $fourth-color;
        }

        .p__error {
            font-size: 2rem;
            margin: 1vw 0;
            color: red;
        }
        
        .form__button {
            width: 100%;
            display: block;
            height: 4vw;
            background-color: $fourth-color;
            border: none;
            border-radius: 0;
            color: $third-color;
            font-size: 1.5vw;
            letter-spacing: 1px;
            margin-bottom: 0;
        }
    
    }

}