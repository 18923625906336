@import '../../../base.styles/_settings';

nav {
    display: block;
    opacity: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transform: translateY(100%) translatex(100%);
    padding: 0;
    margin: 0 -5%;
    transition: 500ms cubic-bezier(.8, .5, .2, 1.4);    

    a:link, a:active, a:visited {
        color: $third-color;
        text-decoration: none;
        position: relative;
        display: block;
    }

    a:focus {
        outline: none;
    }

    a::after {
        content: '';
        bottom: -2.5vw;
        left: 0;
        width: 0%;
        border-bottom: 5px solid $third-color;
        position: absolute;
        transition: 500ms $transition__standart;
    }

    a:hover::after, a:focus::after, .selected::after {
        width: 100%;
        transition: 500ms $transition__standart;
    }

    ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        grid-auto-rows: 1fr;

        li {
            position: relative;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        li::before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
        
        span {
            font-family: oswald, sans-serif;
            font-weight: 400;
            font-size: 10vw;
            line-height: 10vw;
            display: inline-block;
          }
    }

    .nav-lightbox {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        background-color: rgba(255,255,255, 0.75);
        z-index: -1;
    }
  }

nav.open {
    display: block;
    opacity: 1;
    transform: translateY(100%) translatex(0);
    transition: 500ms cubic-bezier(.8, .5, .2, 1.4);
}

@media (min-width: 400px) {
    nav {

        a::after {
            border-bottom: 2vw solid $third-color;
        }
    
        ul {            
            span {
                font-size: 10vw;
                line-height: 10.5vw;
              }
        }
      }
  }

@media (max-width: 959px) and (orientation: landscape), (min-aspect-ratio: 809/1322) {
    nav {  
        
        a::after {
            border-bottom: .5vw solid $third-color;
            bottom: -1vw;
        }
        
        ul {
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));

            span {
                font-size: $font-size__header-mobile-small;
                line-height: 4vw;
              }
        }
      }
  }

  @media (max-width: 667px) and (orientation: landscape) {
    nav {    
        ul {
            span {
                font-size: 4vw;
                line-height: 4.4vw;
              }
        }
      }
  }

  @media (min-width: 960px) {
    nav {
        display: flex;
        opacity: 1;
        bottom: 0;
        position: relative;
        transform: unset;
        padding: 0;
        margin: 0;
        transition: all;
        height: 100%;
        align-items: flex-end;
        width: 75%;

        a:link, a:active, a:visited {
            display: inline;
        }

        .selected {
            border-bottom: 2px solid $second-color;
        }

    a::after {
        content: '';
        bottom: -10%;
        left: 0;
        width: 0%;
        border-bottom: 2px solid $second-color;
        position: absolute;
        transition: 500ms $transition__standart;
    }

    a:hover::after, a:focus::after {
        width: 100%;
        transition: 500ms $transition__standart;
    }
    
        ul {
            list-style-type: none;
            padding: 15px 0;
            margin: 0;
            display: block;
            border-top: 1px solid $third-color;
    
            li {
                display: inline-block;
                position: static;
                box-sizing: border-box;
                background-color: transparent !important;
                padding: 0 2%;
            }
            
            li::before {
                content: '';
                display: static;
                padding-bottom: 0;
            }
            
            span {
                font-family: futura-pt,sans-serif;
                font-weight: 700;
                position: static;
                height: auto;
                width: auto;
                font-weight: 700 !important;
                font-size: $font-size__small-screen;
                line-height: 3.3rem;

                br {
                    display: none;
                }
              }
        }
    
        .nav-lightbox {
            display: none;
            position: static;
        }
      }

    nav.open {
        opacity: 1;
        transform: unset;
        transition: all;
        display: flex;
    }
  }

  @media (min-width: 1366px) {
    nav {
    
        ul {            
            span {
                position: static;
                height: auto;
                width: auto;
                font-weight: 700 !important;
                font-size: $font-size__mid-screen;
              }
        }
      }
  }

  @media (min-width: 1600px) {
    nav {
    
        ul {            
            span {
                position: static;
                height: auto;
                width: auto;
                font-weight: 700 !important;
                font-size: $font-size__big-screen;
              }
        }
      }
  }