$fa-font-patch: '../fonts/webfonts';

$first-color: #44A12B;
$second-color: #F9BC33;
$third-color: #ffffff;
$fourth-color: #666666;


$font-size__big-screen: 2rem;
$font-size__mid-screen: 1.8rem;
$font-size__small-screen: 1.6rem;
$font-size__mobile: 2rem;

$font-size__header-big: 5rem;
$font-size__header-med: 5rem;
$font-size__header-small: 5rem;
$font-size__header-mobile: 3.6rem;
$font-size__header-mobile-small: 3.8vw;
$font-size__header-mobile-xsmall: 2.4rem;

$transition__standart: cubic-bezier(0.55, 0.055, 0.675, 0.19);