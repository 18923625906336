@import '../../base.styles/_settings';

.icon {
    width: 15%;
    height: auto;
    margin-right: 5vw;
}

.contact_square {
    margin-bottom: 5vw;
}

@media (min-width: 400px) {
    .icon {
        width: 15%;
        height: auto;
        margin-right: 5vw;
    }
    
    .contact_square {
        margin-bottom: 3vw;
    }
  }

  @media (min-width: 960px) {
    .icon {
        width: 15%;
        height: auto;
        margin-right: 2vw;
    }
    
    .contact_square {
        margin-bottom: 2vw;
    }
  }