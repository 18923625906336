.btn-menu {
    width: 30px;
    height: 30px;
    position: relative;
    transition: .5s cubic-bezier(.8, .5, .2, 1.4);
    cursor: pointer;
    display: block;
  }
  .btn-menu span{
    width: 5px;
    height: 5px;
    background-color: #fff;
    display: block;
    position: absolute;
  
  }
  .btn-menu:hover span{
    transform: scale(1.2);
    transition: 350ms cubic-bezier(.8, .5, .2, 1.4);
  }
  .btn-menu span:nth-child(1){
    left: 0;
    top: 0;
  }
  .btn-menu span:nth-child(2){
    left: 12px;
    top: 0;
  }
  .btn-menu span:nth-child(3){
    right: 0;
    top: 0;
  }
  .btn-menu span:nth-child(4){
    left: 0;
    top: 12px;
  }
  .btn-menu span:nth-child(5){
    position: absolute;
    left: 12px;
    top: 12px;
  }
  .btn-menu span:nth-child(6){
    right: 0px;
    top: 12px;
  }
  .btn-menu span:nth-child(7){
    left: 0px;
    bottom: 0px;
  }
  .btn-menu span:nth-child(8){
    position: absolute;
    left: 12px;
    bottom: 0px;
  }
  .btn-menu span:nth-child(9){
    right: 0px;
    bottom: 0px;
  }
  .btn-menu.open{
    transform: rotate(180deg);
    cursor: pointer;
    transition: .5s cubic-bezier(.8, .5, .2, 1.4);
  }
  .btn-menu.open span{
    transition-delay: 200ms;
    transition: .5s cubic-bezier(.8, .5, .2, 1.4);
  }
  .btn-menu.open span:nth-child(2) {
    left: 6px;
    top: 6px;
  }
  .btn-menu.open span:nth-child(4) {
    left: 6px;
    top: 18px;
  }
  .btn-menu.open span:nth-child(6) {
    right: 6px;
    top: 6px;
  }
  .btn-menu.open span:nth-child(8) {
    left: 18px;
    bottom: 6px;
  }

  @media (min-width: 960px) {
    .btn-menu {
        display: none;
      }
  }

  @media (hover: none) {
    .btn-menu:hover span{
        transform: unset;
        transition: all;
      }
  }