@import '../../../base.styles/_settings';

.trainings-wrapper {
    height: 0%;
    overflow: hidden;
    transition: 1000ms $transition__standart;

    .trainings {
        max-height: 0;
        transition: 500ms $transition__standart;
    }

    &.open {
        .trainings {
            max-height: 400vh;
            transition: 1500ms $transition__standart;
        }
    }
}

.inner-text {
    font-weight: 900 !important;
}