@import '../../base.styles/_settings';

header {
    height: 10vh;
    min-height: 53px;
    max-height: 108px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    z-index: 999;

    a.logo {
        width: 75%;
        max-width: 312px;
        max-height: 45px;
        text-decoration: none;
        outline: unset;

        img {
            width: 100%;
            height: auto;
        }
    }

    a.logo::after {
        content: '';
        top: 0%;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $second-color;
        position: absolute;
        transition: 500ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    a.logo:hover::after, a.logo:focus::after {
        width: 1%;
        transition: 500ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }


}

@media (min-width: 960px) {
  header {
    padding: 0;
    justify-content: flex-start;

    a.logo {
        width: 20%;
        padding: 0.5% 2.5%;
        max-width: none;

        img {            
            max-height: 45px;
        }
    }


    &::before {
        content: '';
        position: absolute;
        left: 25%;
        top: 0;
        border-right: 1px solid $third-color;
        height: 100%;
        z-index: 10;
    }
}
}