@import '../../base.styles/_settings';

.footer {
    background-color: $first-color;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    color: $third-color;
    font-family: futura-pt,sans-serif;
    font-style: normal;
    padding: 15vw 10vw;

    .footer__contact li {
        text-align: center;
        margin-bottom: 2.5em !important;

        .icon {
            margin: 0 0 0.5em 0 !important;
        }
    }

    a:link, :active, a:visited {
        color: $third-color;
        text-decoration: none;
        position: relative;
        display: inline-block;
    }

    a:focus {
        outline: none;
    }

    a::after {
        content: '';
        bottom: -0.5vw;
        left: 0;
        width: 0%;
        border-bottom: 5px solid $second-color;
        position: absolute;
        transition: 500ms $transition__standart;
    }

    a:hover::after, a:focus::after, .selected::after {
        width: 100%;
        transition: 500ms $transition__standart;
    }

    .footer__logo {
        margin-bottom: 10vw;
    }

    p, ul {
        margin: 0;
    }

    p {
        font-size: 5vw;
        line-height: 7.5vw;
        margin-bottom: 10vw;
        font-weight: 400;
    }

    ul {
        list-style-type: none;
    }

    .description, .links {
        width: 100%;
    }

    .description ul {
        width: 100%;
        padding: 0;
        font-weight: 700;
        font-size: 5vw;
        line-height: 5.5vw;

        li {
            margin-bottom: 5vw;

            &:last-of-type {
                margin-bottom: 10vw;
            }
        }
    }

    .links ul {
        padding: 0;
        font-size: 5vw;
        font-weight: 400;
        text-transform: uppercase;

        &:first-of-type {
            margin-bottom: 10vw;
        }

        li {
            width: 100%;
            margin-bottom: 5vw;

            &:first-of-type {
                color: $second-color;
                font-weight: 600;
            }
        }
    }
}

.footer__copyright {
    background-color: $third-color;
    padding: 10vw;
    text-align: center;
    font-family: futura-pt,sans-serif;
    font-style: normal;
    font-size: 3.5vw;
    line-height: 5.25vw;

    a:link, :active, a:visited {
        color: $fourth-color;
        text-decoration: none;
        transition: 500ms $transition__standart;
    }

    a:hover {
        color: $first-color;
        transition: 500ms $transition__standart;
    }

    a:focus {
        outline: none;
        color: $first-color;
        transition: 500ms $transition__standart;
    }
}

@media (min-width: 960px) {

    .footer {
        background-color: $first-color;
        margin: 0;
        display: flex;
        flex-wrap: nowrap;
        color: $third-color;
        font-family: futura-pt,sans-serif;
        font-style: normal;
        padding: 5vw 10vw;
        justify-content: space-between;
    
        a:link, :active, a:visited {
            color: $third-color;
            text-decoration: none;
        }

        a::after {
            content: '';
            bottom: -0.2vw;
            border-bottom: 2px solid $second-color;
        }
    
        .footer__logo {
            width: 75%;
            margin-bottom: 2.5vw;
        }
    
        p, ul {
            margin: 0;
        }
    
        p {
            font-size: 1vw;
            line-height: 1.5vw;
            margin-bottom: 2.5vw;
            font-weight: 400;
        }
    
        ul {
            list-style-type: none;
        }
    
        .description, .links {
            width: 45%;
        }
    
        .description ul {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;
            padding: 0;
            font-weight: 700;
            font-size: 1vw;
            line-height: 1.5vw;
    
            li {
                width: 30%;
                margin-bottom: 5vw;
    
                &:last-of-type {
                    margin-bottom: 10vw;
                }
            }
        }

        .links {
            display: flex;
            flex-wrap: nowrap;
            margin-top: 7vw;
            justify-content: space-between;
        }

        .links ul {
            padding: 0;
            font-size: 1vw;
            font-weight: 400;
            text-transform: uppercase;
            width: 45%;
    
            &:first-of-type {
                margin-bottom: 0;
            }
    
            li {
                width: 100%;
                margin-bottom: 1.5vw;
    
                &:first-of-type {
                    color: $second-color;
                    font-weight: 600;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    
    .footer__copyright {
        background-color: $third-color;
        padding: 3vw;
        text-align: center;
        font-family: futura-pt,sans-serif;
        font-style: normal;
        font-size: 1.5vw;
        line-height: 2.25vw;
        color: $fourth-color;

        a:link, :active, a:visited {
            color: $fourth-color;
            text-decoration: none;
            transition: 500ms $transition__standart;
        }

        a:hover {
            color: $first-color;
            transition: 500ms $transition__standart;
        }

        a:focus {
            outline: none;
            color: $first-color;
            transition: 500ms $transition__standart;
        }

    }

}