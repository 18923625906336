@import '../../base.styles/_settings';


article a {
    color: #44A12B;
    text-decoration: none;
    transition: all 250ms ease-in-out;

    &:hover {
        color: #F9BC33;
    }
}

article {
    padding: 0 5%;
}

article p {
    line-height: 4.5rem;
    font-size: 3rem;
}

article h1 {
    margin-top: 10%;
    margin-bottom: 5%;
    line-height: 4.5rem;
    font-size: 4rem;
}

@media (min-width: 375px) {

    article h1 {
        line-height: 5.5rem;
        font-size: 5rem;
    }
}

@media (min-width: 960px) {

    article {
        padding: 0 10%;
    }

    article p {
        line-height: 3.75rem;
        font-size: 2.5rem;
    }

    article h1 {
        line-height: 4rem;
        font-size: 3.5rem;
        margin-bottom: 7.5%;
    }
}

@media (min-width: 1370px) {
    article p {
        line-height: 4.5rem;
        font-size: 3rem;
    }

    article h1 {
        line-height: 4.5rem;
        font-size: 4rem;
    }
}

@media (min-width: 1900px) {
    article p {
        line-height: 6.5rem;
        font-size: 4rem;
    }

    article h1 {
        line-height: 5.5rem;
        font-size: 5rem;
    }
}